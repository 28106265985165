import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ChooseSearch from '../search_select';



const SearchManyApi = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTab = (tab: string) => {
    setActiveTab(tab);
  };

  const location = useLocation();

  useEffect(() => {
    console.log(location);
  }, [location]);


  return (

    <>
    <div className="more">
        <div className="more_group">
          <div className="container" style={{ paddingLeft: "0px", paddingRight: "0px", maxWidth: "1250px" }}>
            <div className="row">
              <div className="col-12 col-md-8 col-sm-12 item-content" style={{ paddingLeft: "115px", paddingRight: "0px" }}>
                <h3 style={{ color: "white", marginTop: "40px", marginBottom: "0px", fontSize: "32px" }}>Verified by GS1</h3>
                <h4 style={{ color: "white", marginTop: "12px", fontSize: "18px" }}>Verifique a identidade de produtos com base em informações confiáveis de donos de marcas</h4>
              </div>

              <div className="col-12 col-md-4 col-sm-12 item-content" style={{ textAlign: "right", paddingLeft: "0px", paddingRight: "0px" }}>
                <img src={require('../../../src/banner3.png')} style={{ maxWidth: "600px", maxHeight: "210px" }} className="banner2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="maxContentApi pb0">
        <div className="banner" >
          <div className="container">
            <ChooseSearch handleTab={handleTab} activeTab={activeTab} multiple={true}/>
            
          </div>
        </div>
      </div>


    </>

  );


};

export default SearchManyApi;



