import React, { useState, useContext, useRef } from 'react';
import Modal from "react-modal";
import { IoMdClose } from 'react-icons/io';
import { Tooltip } from "react-tooltip"
import { AiOutlineInfoCircle } from 'react-icons/ai';




const LinkSetGtin = ({ data}: { data: any }) => {
  
  

  return (
    <>


      <div className=''>
        <div className=' linktitle' style={{ marginBottom:'0px'}}>
          <h4  style={{ marginBottom:'0px', fontSize:'26px'}}>Links para informações dos produtos</h4>
        </div>
        <div>
          {data.links.map((item:any) => (
          <>
            <div className='linkItem'>
              <h5 style={{fontSize:'20px'}}>{item.referencedFileType.name}<AiOutlineInfoCircle  style={{marginLeft:"18px"}} className={item.referencedFileType.code.replace("GS1:", "")} /></h5>
              
                <Tooltip anchorSelect={"."+item.referencedFileType.code.replace("GS1:", "")} place="right">
                  <span style={{wordWrap:"break-word", maxWidth:"100px"}}>{item.referencedFileType.definition}</span>
                </Tooltip>
            </div>
            {item.links.map((link:any)=>(
              <>
              <div>
                <span className="linkblue"><a href={item.links[0].href} target="_blank">{link.title+" "}</a></span>
                <span className="linkred">{link.type}</span>
              </div>
              <div className='languageLink'>Idioma:{link.hreflang[0]}</div>
              </>
            ))}
            
          </> 
          ))}
        </div>

      </div>
      
    </>
  );
};

export default LinkSetGtin;
