import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchOneGTIN from '../consultas/search_one/gtin';
import SearchOneGLN from '../consultas/search_one/gln';
import SearchManyGTIN from '../consultas/search_many/gtin';
import SearchManyGLN from '../consultas/search_many/gln';
import ModalChangeScreen from '../../components/ModalChangeScreen';
import { useSelector } from '../../native-state.dev';

interface ChooseSearchProps {
  handleTab: (tab: string) => void;
  activeTab: string;
  multiple: boolean;
}

const ChooseSearch: React.FC<ChooseSearchProps> = ({ handleTab, activeTab, multiple }) => {
  const [searchDone, setState] = useSelector((s: { searchDone: boolean }) => s.searchDone);

  const [isOpenModalRequest, setIsOpenModalRequest] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleModalClick = (p0: string) => {
    if (searchDone) {
      setIsOpenModalRequest(true);
    }else{
      handleTab(p0)
    }
  };

  const handleChangeScreen = () => {
    setState({searchDone:false});

    setIsOpenModalRequest(false);
    if(activeTab==="tab1"){
      handleTab("tab2")
    }else{
      handleTab("tab1")
    }
  };

  const closeModal = () => setIsOpenModalRequest(false);
  const exportB = () => {
    setIsOpenModalRequest(false);
    const exportButton = document.getElementsByName("buttonExport")[0] as HTMLButtonElement | undefined;
    exportButton?.click();
  };
  return (
    <>
      <div className="row rowTitleApis">
        <div className="col-12 col-md-8 pt-md-0">
          <h1 className="gs1-h1 titlePrincipal" style={{ color: "#002c6c" }}>
            <img src="/check-green.png" className="imgcheck-green" />
            Verified by GS1
          </h1>
          <p className="titleDescriptionMin">
            Verifique a identidade de produtos com base em informações confiáveis de donos de marcas
          </p>
        </div>
      </div>

      <div className="bannertot">
        <div className="rowBoxApi bgBoxApi">
          <div className={activeTab === "tab1" ? "boxApiActive" : "boxApi"} onClick={() => { handleModalClick("tab1"); }}>
            <label>
              <div className="boxContent">
                <img src="/gtin.svg" width="76" height="45px" className="boxIcon" />
                <h4 className="boxTitle" style={{ maxWidth: "160px" }}>Verificar Produto</h4>
                <p className="boxSubtitle">Busque através do número do código de barras (GTIN)</p>
              </div>
            </label>
          </div>

          <div className={activeTab === "tab2" ? "boxApiActive" : "boxApi"} onClick={() => { handleModalClick("tab2"); }}>
            <label>
              <div className="boxContent">
                <img style={{ marginLeft: "-19px" }} src="/gln.svg" width="76" height="45px" className="boxIcon" />
                <h4 className="boxTitle">Verificar Localização</h4>
                <p className="boxSubtitle">Busque através da chave de localização (GLN)</p>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div>
        {!multiple ? (
          activeTab === "tab1" ? <SearchOneGTIN /> : <SearchOneGLN />
        ) : (
          activeTab === "tab1" ? <SearchManyGTIN /> : <SearchManyGLN />
        )}
      </div>

      <ModalChangeScreen
        isOpen={isOpenModalRequest}
        onClose={closeModal}
        onChangeScreen={handleChangeScreen}
        onExport={exportB}
      />
    </>
  );
};

export default ChooseSearch;
